import React, { useEffect, useState } from 'react'
import { Header, HomeBody, LoginModal, Notification } from '../../components'
import { ProductSection } from '../../screens'
import './Home.css'
import { onAuthStateChanged } from 'firebase/auth'
import { auth, db } from '../../utils/firebase'
import { useUserStore } from '../../utils/userStore'
import { getOrders, getRestaurants } from '../../endpoints/endpoints'

function MainScreen() {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [toggleBtn, setToggleBtn] = useState(false)
    // const [user, setUser] = useState<any>(null)

    const { storeOrders, fetchUserInfo, storeRestaurantsList } = useUserStore()

    useEffect(() => {
        if (localStorage.getItem('user') !== null) {
            const userId = localStorage.getItem('user')
            if (userId) {
                fetchUserInfo(JSON.parse(userId))
            } else {
                fetchUserInfo()
            }
        }
    }, [fetchUserInfo, localStorage])

    const openModal = () => {
        setModalIsOpen(true)
    }

    const closeModal = () => {
        setModalIsOpen(false)
    }

    useEffect(() => {
        const fetchOrders = async () => {
            const orderlist = await getOrders()
            const restaurantlist = await getRestaurants()
            storeOrders(orderlist)
            storeRestaurantsList(restaurantlist)
        }

        fetchOrders() // Call the async function
    }, [])

    return (
        <div className="app">
            <Notification />
            <Header
                openModal={openModal}
                toggleBtn={toggleBtn}
                setToggleBtn={setToggleBtn}
            />
            <LoginModal modalIsOpen={modalIsOpen} closeModal={closeModal} />
            <main className="mobile">
                {toggleBtn ? (
                    <HomeBody
                        openModal={openModal}
                        toggleBtn={toggleBtn}
                        setToggleBtn={setToggleBtn}
                    />
                ) : (
                    <ProductSection />
                )}
            </main>
            <main className="desktop">
                <HomeBody
                    openModal={openModal}
                    toggleBtn={toggleBtn}
                    setToggleBtn={setToggleBtn}
                />
                <ProductSection />
            </main>
        </div>
    )
}

export default MainScreen
