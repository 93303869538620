import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font,
    Image,
} from '@react-pdf/renderer'
import './GRNPdf.css'

import TopLogo from '../../assets/img/koralbyte.png'
import BackgroundLogo from '../../assets/img/cocoaLogo.png'
import ExtraLogo from '../../assets/img/cocoaLogo.png'

// Register fonts
Font.register({
    family: 'Helvetica',
    src: 'https://fonts.gstatic.com/s/helvetica/v6/7cHlr2EbQJq10pFw0WbCsgLUuEpTyoUstqEm5AMlJo4.woff2',
})

const styles = StyleSheet.create({
    page: {
        position: 'relative',
        padding: 30,
        fontFamily: 'Helvetica',
        fontSize: 12,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 30,
        alignItems: 'center',
    },
    topLogo: {
        width: 90,
        height: 70,
    },
    backgroundLogo: {
        position: 'absolute',
        width: 200,
        height: 200,
        opacity: 0.08,
        top: '45%',
        left: '45%',
        transform: 'translate(-50%, -50%)',
        zIndex: -1,
        alignItems: 'center',
    },
    ribbon: {
        position: 'absolute',
        top: 55,
        right: -60,
        backgroundColor: '#5e239d',
        color: '#fff',
        fontSize: 12,
        fontWeight: 'bold',
        padding: '8px 70px',
        transform: 'rotate(45deg)',
        textAlign: 'center',
        boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
        borderWidth: 3,
        borderColor: '#6ec046',
        borderStyle: 'solid',
    },
    extraLogo: {
        width: 50,
        height: 50,
        position: 'absolute',
        top: 5,
        right: 100,
    },
    companyInfo: {
        fontSize: 10,
        marginBottom: 20,
    },
    logoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
    },
    fromSection: {
        padding: 4,
        textAlign: 'left',
        marginTop: 30,
    },
    fromText: {
        fontSize: 10,
        marginBottom: 5,
    },
    poInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15,
    },
    sectionHeader: {
        fontWeight: 'bold',
        marginBottom: 5,
        fontSize: 16,
    },
    deliveryDetailsContainer: {
        backgroundColor: '#e0e0e0',
        padding: 10,
        borderRadius: 5,
        marginBottom: 15,
    },
    deliveryText: {
        fontSize: 10,
        marginBottom: 5,
    },
    invoicedToContainer: {
        backgroundColor: '#f5f5f5',
        padding: 10,
        borderRadius: 5,
        marginBottom: 15,
    },
    invoicedToText: {
        fontSize: 10,
        marginBottom: 5,
    },
    table: {
        width: '100%',
        border: '1px solid #ccc',
        marginBottom: 10,
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        paddingVertical: 4,
    },
    tableCol: {
        width: '25%',
        padding: 4,
    },
    tableHeader: {
        backgroundColor: '#f5f5f5',
        fontWeight: 'bold',
        paddingVertical: 5,
    },
    tableCell: {
        fontSize: 10,
        textAlign: 'center',
    },
    balanceRow: {
        borderTopWidth: 1,
        borderTopColor: '#ccc',
        paddingTop: 4,
    },
    footer: {
        textAlign: 'center',
        fontSize: 10,
        marginTop: 20,
        color: '#777',
    },
    // footerLogo: {
    //     width: 30,  // Small size for the footer logo
    //     height: 30,
    //     marginLeft: 50, // Space between the text and logo
    // },
    totalSection: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 10,
        padding: 10,
    },
    totalText: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    totalAmount: {
        fontSize: 12,
        fontWeight: 'bold',
        paddingLeft: 10,
    },
})

// Create Document Component
const GRNPdf = (props: any) => {
    const products = props.cartItems.map((item: any) => {
        return {
            product: item.ProductName,
            quantity: item.quantity,
            price: item.UnitPrice,
            amount: `$ ${item.quantity * item.UnitPrice}`,
        }
    })

    const TotalAmount = products.reduce(
        (total: any, product: any) => total + product.price * product.quantity,
        0
    )

    const { OrderDate, OrderID, ShippingAddress } = props.orderData

    const { CompanyName, FocalPersonHp } = props.restaurant

    // Extract year, month, and day
    const year = OrderDate.slice(0, 4)
    const month = OrderDate.slice(4, 6)
    let day = OrderDate.slice(6, 8)
    let delieveryDay = day + 5

    // Format as DD/MM/YYYY
    const formattedDate = `${day}/${month}/${year}`
    const delieveryDate = `${delieveryDay}/${month}/${year}`
    const orderDetails = {
        customerCompany: 'COCOA Goods Received Note',
        poNumber: OrderID,
        poDate: formattedDate,
        deliveryDate: delieveryDate,
        supplierCompany: CompanyName,
        supplierContact: FocalPersonHp,
        supplierAddress: ShippingAddress,

        descriptionDetails: products,
        total: TotalAmount,
        paymentDetails: [
            {
                date: formattedDate,
                gateway: 'Cash on Delivery',
                transactionId: '-',
                amount: TotalAmount,
            },
        ],
    }

    return (
        <Document>
            <Page style={styles.page}>
                <Image src={BackgroundLogo} style={styles.backgroundLogo} />

                <Text style={styles.ribbon}>Goods Received Note</Text>

                <View style={styles.logoContainer}>
                    <Image src={TopLogo} style={styles.topLogo} />
                    <Image src={ExtraLogo} style={styles.extraLogo} />{' '}
                    {/* New logo next to ribbon */}
                </View>

                <View style={styles.fromSection}>
                    <Text style={styles.fromText}>
                        Koralbyte Inc,
                        <br />
                        LG-01, CoPlace 3, Block 3740 , Persiaran Apec Cyber 8,
                        63500 Cyberjaya.
                    </Text>
                    <Text style={styles.fromText}>
                        Bank Name: Maybank Berhad
                        <br />
                        Payee Name: RockSoft Cloud Sdn. Bhd.
                        <br />
                        Account Number: 568603065287
                    </Text>
                    <Text style={styles.fromText}>
                        Other Payment Method:
                        <br />
                        https://system.rocksoft.net/knowledgebase/179/How-To-Make-Payment-to-RockSoft.html
                    </Text>
                </View>

                <View style={styles.deliveryDetailsContainer}>
                    <Text style={styles.sectionHeader}>
                        Purchase order #: {orderDetails.poNumber}
                    </Text>
                    <Text style={styles.deliveryText}>
                        P.O. Date: {orderDetails.poDate}
                    </Text>
                    <Text style={styles.deliveryText}>
                        Delivery Date: {orderDetails.deliveryDate}
                    </Text>
                </View>

                <View style={styles.invoicedToContainer}>
                    <Text style={styles.sectionHeader}>Invoiced To:</Text>
                    <Text style={styles.fromText}>Rizwan Sweets 545222442</Text>
                    <Text style={styles.fromText}>
                        609 King St W, Toronto, Ontario M5V 1M5 Canada
                    </Text>
                </View>
                <Text style={styles.sectionHeader}>Order Details</Text>
                <View style={styles.table}>
                    <View style={[styles.tableRow, styles.tableHeader]}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Product</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Quantity</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Price</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Amount</Text>
                        </View>
                    </View>
                    {orderDetails.descriptionDetails.map(
                        (item: any, index: number) => (
                            <View style={styles.tableRow} key={index}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {item.product}
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {item.quantity}
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {item.price}
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {item.amount}
                                    </Text>
                                </View>
                            </View>
                        )
                    )}
                </View>

                <Text style={styles.sectionHeader}>Payment Details:</Text>
                <View style={styles.table}>
                    <View style={[styles.tableRow, styles.tableHeader]}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                Transaction Date
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Gateway</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Transaction ID</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Amount</Text>
                        </View>
                    </View>
                    {orderDetails.paymentDetails.map(
                        (payment: any, index: number) => (
                            <View style={styles.tableRow} key={index}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {payment.date}
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {payment.gateway}
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {payment.transactionId}
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        RM {payment.amount}
                                    </Text>
                                </View>
                            </View>
                        )
                    )}
                    <View style={[styles.tableRow, styles.balanceRow]}>
                        <View style={[styles.tableCol, { flex: 1 }]}>
                            <Text
                                style={[
                                    styles.tableCell,
                                    { textAlign: 'right', fontWeight: 'bold' },
                                ]}
                            >
                                Balance
                            </Text>
                        </View>
                        <View
                            style={[
                                styles.tableCol,
                                { width: 'auto' },
                                { alignItems: 'flex-end' },
                            ]}
                        >
                            <Text
                                style={[
                                    styles.tableCell,
                                    { fontWeight: 'bold' },
                                ]}
                            >
                                $ 0.00
                            </Text>
                        </View>
                    </View>
                </View>

                <View style={styles.totalSection}>
                    <Text style={styles.totalText}>Total Amount:</Text>
                    <Text style={styles.totalAmount}>
                        $ {orderDetails.total}
                    </Text>
                </View>

                {/* Footer with tiny logo */}
                <View style={styles.footer}>
                    <Text>
                        "Powered by COCOA - a project of Koralbyte Technologies"{' '}
                        {new Date().toLocaleDateString()}
                    </Text>
                    {/* <Image src={ExtraLogo} style={styles.footerLogo} /> */}
                </View>
            </Page>
        </Document>
    )
}

export default GRNPdf
