import React, { useCallback, useEffect, useState, useMemo } from 'react'
import './ChatBox.css'
import { useChatStore } from '../../utils/useChatStore'
import {
    addDoc,
    arrayUnion,
    collection,
    doc,
    getDoc,
    updateDoc,
} from 'firebase/firestore'
import { db } from '../../utils/firebase'
import { useUserStore } from '../../utils/userStore'
import upload from '../../utils/upload'
import Attachment from '../../assets/img/attachment.png'
import DisabledSendMessage from '../../assets/img/sendMessage-disabled.png'
import SendMessage from '../../assets/img/sendMessage.png'
import { ProductDropupMenu } from '../../components'
import { BACKEND_URL } from '../../constant'
import { Tooltip } from '@mui/material'
import { getOrders } from '../../endpoints/endpoints'

const ChatBox = () => {
    const [text, setText] = useState('')
    const [orderlist, setOrderlist] = useState()
    const [img, setImg] = useState({
        file: null,
        url: '',
    })
    const { currentUser, productDetails } = useUserStore()
    const { chatId, user, uploadImg, clearImg } = useChatStore()

    const handleImage = useCallback(
        async (e: any) => {
            uploadImg(e)
            if (e.target.files[0]) {
                setImg({
                    file: e.target.files[0],
                    url: URL.createObjectURL(e.target.files[0]),
                })
            }
            await handleSend(text, img)
            clearImg()
        },
        [img, text]
    )

    useEffect(() => {
        const fetchOrders = async () => {
            const orderlisting = await getOrders()
            setOrderlist(orderlisting)
        }

        fetchOrders()
    }, [])

    const handleSend = useCallback(
        async (text: any, img: any) => {
            if (text === '' && img['file'] === null && img['url'] === '') return

            let imgUrl = null

            try {
                if (img.file) {
                    imgUrl = await upload(img.file)
                } else if (img.url) {
                    imgUrl = img.url
                }

                const messageData = {
                    senderId: currentUser.id,
                    text,
                    createdAt: new Date(),
                    ...(imgUrl && { img: imgUrl }),
                }

                if (text !== '' && img['url'] !== '') {
                    messageData.productCard = true
                }

                // Add each message as a new document in the 'messages' sub-collection
                const messagesCollectionRef = collection(
                    db,
                    `chats/${chatId}/messages`
                )
                await addDoc(messagesCollectionRef, messageData)

                const userIDs = [currentUser.id, user]
                userIDs.forEach(async (id) => {
                    const userChatsRef = doc(db, 'user-chat', id)
                    const userChatsSnapshot = await getDoc(userChatsRef)
                    if (userChatsSnapshot.exists()) {
                        const userChatsData = userChatsSnapshot.data()

                        const chatIndex = userChatsData.chats.findIndex(
                            (c: any) => c.chatId === chatId
                        )

                        if (chatIndex !== -1) {
                            userChatsData.chats[chatIndex].lastMessage = text
                            if (text !== '' && img['url'] !== '') {
                                userChatsData.chats[chatIndex].productCard =
                                    true
                            }
                            userChatsData.chats[chatIndex].isSeen =
                                id === currentUser.id ? true : false
                            userChatsData.chats[chatIndex].updatedAt =
                                Date.now()

                            await updateDoc(userChatsRef, {
                                chats: userChatsData.chats,
                            })
                        }
                    }
                })
            } catch (e) {
                console.log(e)
            }

            setImg({
                file: null,
                url: '',
            })

            setText('')
        },
        [chatId, currentUser.id, img, text, user]
    )

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter' && text.trim() !== '') {
            handleSend(text, img)
        }
    }

    const sendProductCards = useCallback(
        async (products: any) => {
            for (const product of products) {
                const productText = `${product.ProductName}./n${product.Description}`
                const productImg = {
                    file: null,
                    url: `${BACKEND_URL}/images/${product.MainImage}`,
                }
                await handleSend(productText, productImg)
            }
        },
        [handleSend]
    )

    // Memoize the icon rendering logic to prevent re-renders
    const sendMessageIcon = useMemo(() => {
        if (text.trim() === '' && img['file'] === null) {
            return DisabledSendMessage
        }
        return SendMessage
    }, [text, img])

    return (
        <div className="message-input">
            <input
                type="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Type your message"
                onKeyPress={handleKeyPress}
            />
            <ProductDropupMenu sendProductCards={sendProductCards} />

            <label htmlFor="file" className="uploadImgIcon">
                <Tooltip title="Upload Attachment">
                    <img src={Attachment} alt="attachment" />
                </Tooltip>
            </label>
            <input
                type="file"
                id="file"
                style={{ display: 'none' }}
                accept="multiple"
                onChange={handleImage}
            />
            <div className="sent-btn" onClick={() => handleSend(text, img)}>
                <Tooltip title="Send Message">
                    <img src={sendMessageIcon} alt="send" />
                </Tooltip>
            </div>
        </div>
    )
}

export default ChatBox
