import React from 'react'
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font,
    Image,
} from '@react-pdf/renderer'

import TopLogo from '../../assets/img/koralbyte.png'
import BackgroundLogo from '../../assets/img/cocoaLogo.png'
import ExtraLogo from '../../assets/img/cocoaLogo.png'

// Register fonts
Font.register({
    family: 'Helvetica',
    src: 'https://fonts.gstatic.com/s/helvetica/v6/7cHlr2EbQJq10pFw0WbCsgLUuEpTyoUstqEm5AMlJo4.woff2',
})

const styles = StyleSheet.create({
    page: {
        position: 'relative',
        padding: 30,
        fontFamily: 'Helvetica',
        fontSize: 12,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 30,
    },
    topLogo: {
        width: 90,
        height: 70,
    },
    backgroundLogo: {
        position: 'absolute',
        width: 200,
        height: 200,
        opacity: 0.08,
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    ribbon: {
        position: 'absolute',
        top: 55,
        right: -60,
        backgroundColor: '#97df4a',
        color: '#fff',
        fontSize: 12,
        fontWeight: 'bold',
        padding: '8px 70px',
        transform: 'rotate(45deg)',
        textAlign: 'center',
        borderWidth: 3,
        borderColor: '#6ec046',
        borderStyle: 'solid',
    },
    extraLogo: {
        width: 50,
        height: 50,
        position: 'absolute',
        top: 5,
        right: 100,
    },
    fromSection: {
        padding: 4,
        textAlign: 'left',
        marginTop: 30,
    },
    fromText: {
        fontSize: 10,
        marginBottom: 5,
    },
    deliveryDetailsContainer: {
        backgroundColor: '#e0e0e0',
        padding: 10,
        borderRadius: 5,
        marginBottom: 15,
    },
    invoicedToContainer: {
        backgroundColor: '#f5f5f5',
        padding: 10,
        borderRadius: 5,
        marginBottom: 15,
    },
    table: {
        width: '100%',
        border: '1px solid #ccc',
        marginBottom: 10,
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        paddingVertical: 4,
    },
    tableHeader: {
        backgroundColor: '#f5f5f5',
        fontWeight: 'bold',
        paddingVertical: 5,
    },
    tableCol: {
        width: '25%',
        padding: 4,
    },
    tableCell: {
        fontSize: 10,
        textAlign: 'center',
    },
    totalSection: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 10,
        padding: 10,
    },
    totalText: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    totalAmount: {
        fontSize: 12,
        fontWeight: 'bold',
        paddingLeft: 10,
    },
    footer: {
        textAlign: 'center',
        fontSize: 10,
        marginTop: 20,
        color: '#777',
    },
    sectionHeader: {
        fontWeight: 'bold',
        marginBottom: 5,
        fontSize: 16,
    },
    balanceRow: {
        borderTopWidth: 1,
        borderTopColor: '#ccc',
        paddingTop: 4,
    },
})

interface Product {
    product: string
    quantity: number
    price: number
    amount: string
}

interface PaymentDetail {
    date: string
    gateway: string
    transactionId: string
    amount: number
}

interface DeliveryOrderProps {
    cartItems: { ProductName: string; quantity: number; UnitPrice: number }[]
    orderData: {
        OrderDate: string
        OrderID: string
        TotalAmount: number
        ShippingAddress: string
    }
    restaurant: { CompanyName: string; FocalPersonHp: string }
}

const DeliveryOrder: React.FC<DeliveryOrderProps> = (props) => {
    const products: Product[] = props.cartItems.map((item) => ({
        product: item.ProductName,
        quantity: item.quantity,
        price: item.UnitPrice,
        amount: `$ ${item.quantity * item.UnitPrice}`,
    }))

    const paymentDetails: PaymentDetail[] = [
        {
            date: `${props.orderData.OrderDate.slice(
                6,
                8
            )}/${props.orderData.OrderDate.slice(
                4,
                6
            )}/${props.orderData.OrderDate.slice(0, 4)}`,
            gateway: 'Cash on Delivery',
            transactionId: '-',
            amount: props.orderData.TotalAmount,
        },
    ]

    const TotalAmount = products.reduce(
        (total, product) => total + product.price * product.quantity,
        0
    )

    const { OrderDate, OrderID, ShippingAddress } = props.orderData
    const { CompanyName, FocalPersonHp } = props.restaurant

    const year = OrderDate.slice(0, 4)
    const month = OrderDate.slice(4, 6)
    const day = OrderDate.slice(6, 8)
    const deliveryDay = (parseInt(day) + 5).toString().padStart(2, '0')

    const formattedDate = `${day}/${month}/${year}`
    const deliveryDate = `${deliveryDay}/${month}/${year}`

    return (
        <Document>
            <Page style={styles.page}>
                <Image src={BackgroundLogo} style={styles.backgroundLogo} />
                <Text style={styles.ribbon}>DELIVERY ORDER</Text>

                <View style={styles.header}>
                    <Image src={TopLogo} style={styles.topLogo} />
                    <Image src={ExtraLogo} style={styles.extraLogo} />
                </View>

                <View style={styles.fromSection}>
                    <Text style={styles.fromText}>
                        Koralbyte Inc,
                        <br />
                        LG-01, CoPlace 3, Block 3740, Persiaran Apec Cyber 8,
                        63500 Cyberjaya.
                    </Text>
                    <Text style={styles.fromText}>
                        Bank Name: Maybank Berhad
                        <br />
                        Payee Name: RockSoft Cloud Sdn. Bhd.
                        <br />
                        Account Number: 568603065287
                    </Text>
                    <Text style={styles.fromText}>
                        Other Payment Method:
                        <br />
                        https://system.rocksoft.net/knowledgebase/179/How-To-Make-Payment-to-RockSoft.html
                    </Text>
                </View>

                <View style={styles.deliveryDetailsContainer}>
                    <Text style={styles.sectionHeader}>
                        Purchase order #: {OrderID}
                    </Text>
                    <Text style={styles.fromText}>
                        P.O. Date: {formattedDate}
                    </Text>
                    <Text style={styles.fromText}>
                        Delivery Date: {deliveryDate}
                    </Text>
                </View>

                <View style={styles.invoicedToContainer}>
                    <Text style={styles.sectionHeader}>Invoiced To:</Text>
                    <Text style={styles.fromText}>
                        {CompanyName} {FocalPersonHp}
                    </Text>
                    <Text style={styles.fromText}>{ShippingAddress}</Text>
                </View>

                <Text style={styles.sectionHeader}>Order Details</Text>
                <View style={styles.table}>
                    <View style={[styles.tableRow, styles.tableHeader]}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Product</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Quantity</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Price</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Amount</Text>
                        </View>
                    </View>
                    {products.map((item, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {item.product}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {item.quantity}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    $ {item.price}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {item.amount}
                                </Text>
                            </View>
                        </View>
                    ))}
                </View>

                <Text style={styles.sectionHeader}>Payment Details:</Text>
                <View style={styles.table}>
                    <View style={[styles.tableRow, styles.tableHeader]}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                Transaction Date
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Gateway</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Transaction ID</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Amount</Text>
                        </View>
                    </View>
                    {paymentDetails.map((detail, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {detail.date}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {detail.gateway}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {detail.transactionId}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    $ {detail.amount}
                                </Text>
                            </View>
                        </View>
                    ))}
                </View>

                <View style={[styles.tableRow, styles.balanceRow]}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Total Amount:</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>$ {TotalAmount}</Text>
                    </View>
                </View>

                <View style={styles.footer}>
                    <Text style={styles.footer}>
                        Terms and conditions apply. This is a system-generated
                        document.
                    </Text>
                </View>
            </Page>
        </Document>
    )
}

export default DeliveryOrder
