// src/components/HomeBody.js
import React from 'react'
import './HomeBody.css'
import HeaderImage from '../../assets/img/header2.png'
import BusinessModalImage from '../../assets/img/home1.png'
import Restaurant from '../../assets/img/restaurant.png'
import Supplier from '../../assets/img/hotel.png'
import Competitor from '../../assets/img/distribution.png'
import KeySuppliers from '../../assets/img/franchise.png'
import MarketSize from '../../assets/img/catering.png'
import Trend from '../../assets/img/event.png'
import CEO from '../../assets/img/Ceo.png'
import CTO from '../../assets/img/cto.png'
import EngineeringHead from '../../assets/img/engineeringHead.png'
import OperationsHead from '../../assets/img/operationsHead.png'
import saltPic from '../../assets/img/salt_logo_FINAL-01.png'
import GraphicsDesigner from '../../assets/img/Designer.png'
import Footer from '../Footer/Footer'
import supplierFeat from '../../assets/img/Mockup.png'
import restaurantFeat from '../../assets/img/phone.png'
import feature1 from '../../assets/img/unique-selling-pts3.png'
import feature2 from '../../assets/img/unique-selling-pts4.png'
import productline1 from '../../assets/img/productline1.png'
import uniform from '../../assets/img/uniform.png'
import cleaning from '../../assets/img/cleaning.png'
import corporate from '../../assets/img/corporate.png'
import software from '../../assets/img/software.png'
import restaurant from '../../assets/img/restaurant.png'
import kitchen from '../../assets/img/kitchen.png'
import restaurantPurple from '../../assets/img/restaurant-purple.png'

const HomeBody = ({ openModal, toggleBtn, setToggleBtn }: any) => {
    function SaltScroll() {
        return (
            <div className="scroll-container">
                <div className="scroll-content">
                    {[...Array(20)].map((_, index) => (
                        <img
                            key={index}
                            src={saltPic}
                            alt="salt pic"
                            className="salt-pic"
                        />
                    ))}
                </div>
            </div>
        )
    }
    return (
        <div className="main-content">
            <section className="hero">
                <div className="hero-text">
                    <p>
                        Welcome to COCOA, the ultimate procurement hub for
                        Restaurants, Cafes and Caterers for their end-to-end
                        needs. From Routine Kitchen Supplies and Specialized
                        Equipment to Advanced Software and Corporate solutions,
                        COCOA connects you directly with top Suppliers operating
                        in the F&B industry.
                    </p>
                    <div className="hero-buttons">
                        <button
                            className="supplier-btn"
                            onClick={() => {
                                const targetElement = document.getElementById(
                                    'vendors-app-feature'
                                )
                                if (targetElement) {
                                    targetElement.scrollIntoView({
                                        behavior: 'smooth',
                                    })
                                }
                            }}
                        >
                            Vendor
                        </button>
                        <button
                            onClick={() => {
                                const targetElement = document.getElementById(
                                    'top-suppliers-heading'
                                )
                                if (targetElement) {
                                    targetElement.scrollIntoView({
                                        behavior: 'smooth',
                                    })
                                }
                            }}
                            className="restaurant-btn"
                        >
                            Restaurants
                        </button>
                    </div>
                </div>
                <div className="hero-image-container">
                    <img
                        src={HeaderImage}
                        alt="Hero Illustration"
                        className="hero-image"
                    />
                </div>
            </section>
            {/* <section className="business-model">
                <div className="business-content">
                    <div className="business-image">
                        <img
                            src={BusinessModalImage}
                            alt="Business Model"
                            className="section-image"
                        />
                    </div>
                    <div className="business-text">
                        <h2>Business Model</h2>
                        <p>
                            COCOA operates as a B2B marketplace where suppliers
                            list their products and restaurants place orders. It
                            generates revenue through transaction fees,
                            subscription models, and premium services.
                        </p>
                        <div className="value-propositions">
                            <h2>Unique Value Propositions</h2>
                            <ul>
                                <li>
                                    <strong>For Suppliers:</strong> Increased
                                    market reach, streamlined order management,
                                    and data analytics for demand forecasting.
                                </li>
                                <br />
                                <li>
                                    <strong>For Customers:</strong> Access to a
                                    wide range of suppliers, competitive
                                    pricing, and simplified procurement
                                    processes.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="financial-implication">
                <div className="suppliers">
                    <h1 className="body-section-1-text">VENDOR CATEGORIES</h1>
                    {SaltScroll()}
                </div>
            </section>

            <h1 id="top-suppliers-heading" className="body-section-1-text">
                PRODUCTS LINE
            </h1>
            <section className="feature-grid">
                <div
                    className="feature-card"
                    style={{ backgroundColor: '#5f239d' }}
                >
                    <div className="feature-icon">
                        <img src={restaurant} alt="Icon 1" />
                    </div>
                    <h4 style={{ color: 'yellow' }}>FURNITURE & FURNISHINGS</h4>
                    <p style={{ color: 'white' }}>
                        Our venders' state-of-art Essential tools, appliances,
                        and furniture needed for a functional and inviting
                        restaurant environment, from kitchen to decor.
                    </p>
                </div>
                <div
                    className="feature-card"
                    style={{ backgroundColor: '#f8d200' }}
                >
                    <div className="feature-icon">
                        <img src={kitchen} alt="Icon 2" />
                    </div>
                    <h4>COMMERCIAL KITCHEN EQUIPMENT</h4>
                    <p>
                        Our kitchen items suppliers are businesses that provide
                        essential tools, utensils, and appliances for cooking,
                        food preparation, and kitchen organization.
                    </p>
                </div>
                <div
                    className="feature-card"
                    style={{ backgroundColor: '#5f239d' }}
                >
                    <div className="feature-icon">
                        <img src={software} alt="Icon 1" />
                    </div>
                    <h4 style={{ color: 'yellow' }}>F&B SOFTWARE SOLUTIONS</h4>
                    <p style={{ color: 'white' }}>
                        We provide F&B Software which streamlines restaurant
                        operations, from inventory and order management to
                        customer engagement, enhancing efficiency and service
                        quality.
                    </p>
                </div>
                <div
                    className="feature-card"
                    style={{ backgroundColor: '#f8d200' }}
                >
                    <div className="feature-icon">
                        <img src={corporate} alt="Icon 2" />
                    </div>
                    <h4 style={{ color: 'white' }}>F&B CORPORATE SOLUTIONS</h4>
                    <p>
                        Through our F&B Corporate Solutions you can optimize
                        business operations, from supply chain management to
                        financial insights, driving growth and efficiency.
                    </p>
                </div>
                <div
                    className="feature-card"
                    style={{ backgroundColor: '#5f239d' }}
                >
                    <div className="feature-icon">
                        <img src={cleaning} alt="Icon 1" />
                    </div>
                    <h4 style={{ color: 'yellow' }}>
                        CLEANING & HYGIENE PRODUCTS
                    </h4>
                    <p style={{ color: 'white' }}>
                        We invite suppliers to provide top-quality Cleaning &
                        Hygiene Products, ensuring safe, sanitary solutions for
                        businesses.
                    </p>
                </div>
                <div
                    className="feature-card"
                    style={{ backgroundColor: '#f8d200' }}
                >
                    <div className="feature-icon">
                        <img src={uniform} alt="Icon 2" />
                    </div>
                    <h4>UNIFORMS & STAFF APPAREL</h4>
                    <p>
                        Our Uniforms & Staff Apparel offer stylish, comfortable,
                        and durable attire that reflects your brand’s
                        professionalism.
                    </p>
                </div>
            </section>

            <section className="app-features">
                <h1 className="body-section-1-text" id="vendors-app-feature">
                    VENDORS APP FEATURES
                </h1>
                <div className="feature-item">
                    <div className="feature-image">
                        <img src={supplierFeat} alt="Supplier App Feature 1" />
                    </div>
                    <div className="feature-text">
                        <h3>Reach Large Network of Restaurants</h3>
                        <p>
                            Reach a wide range of restaurants seeking everything
                            from daily essentials to specialized products and
                            services.
                        </p>
                    </div>
                </div>
                <div className="feature-item">
                    <div className="feature-text">
                        <h3>Real-Time Orders & Communication</h3>
                        <p>
                            Instantly engage with restaurant buyers, receive
                            orders, and respond to inquiries in real time,
                            ensuring a fast and efficient sales process.
                        </p>
                        <button className="learn-more-btn">Learn More</button>
                    </div>
                    <div className="feature-image">
                        <img src={supplierFeat} alt="Supplier App Feature 2" />
                    </div>
                </div>

                <h1 className="body-section-1-text">PRODUCTS APP FEATURES</h1>
                <div className="feature-item">
                    <div className="feature-image">
                        <img
                            style={{ maxWidth: '40%' }}
                            src={restaurantFeat}
                            alt="Restaurant App Feature 1"
                        />
                    </div>
                    <div className="feature-text">
                        <h3>Network of Suppliers across Ontario</h3>
                        <p>
                            Find the perfect suppliers for everything. Whether
                            you need Routine Kitchen Supplies, Equipment for
                            your new outlet, Software Solutions, or services to
                            keep you compliant, COCOA has it all!
                        </p>
                    </div>
                </div>
                <div className="feature-item">
                    <div className="feature-text">
                        <h3>Chat, Negotiate and Save Cost</h3>
                        <p>
                            Maintain control over your budget by negotiating
                            with the Suppliers, and pay what you think makes
                            sense.
                        </p>
                        <button className="learn-more-btn">
                            Explore Features
                        </button>
                    </div>
                    <div className="feature-image">
                        <img
                            style={{ maxWidth: '40%' }}
                            src={restaurantFeat}
                            alt="Restaurant App Feature 2"
                        />
                    </div>
                </div>
            </section>

            <section className="targeted-market">
                <h1 className="body-section-1-text">PRIMARY MARKET</h1>
                <div className="market-segments">
                    <div className="market-segment">
                        <img
                            src={restaurantPurple}
                            alt="Restaurants"
                            className="segment-image"
                        />
                        <h3>Restaurants & Cafes</h3>
                        <p>
                            Shop top-quality essentials for Restaurants and
                            Cafes, simplifying your sourcing process.
                        </p>
                    </div>
                    <div className="market-segment">
                        <img
                            src={Supplier}
                            alt="Suppliers"
                            className="segment-image"
                        />
                        <h3>Chain & Solo Hotels</h3>
                        <p>
                            Discover quality supplies for Hotel Chains and
                            Independent Hotels, delivered seamlessly.
                        </p>
                    </div>

                    <div className="market-segment">
                        <img
                            src={MarketSize}
                            alt="Market Size"
                            className="segment-image"
                        />
                        <h3>Catering Companies</h3>
                        <p>
                            Find premium supplies for Catering Companies,
                            tailored for flawless event service.
                        </p>
                    </div>
                    <div className="market-segment">
                        <img
                            src={KeySuppliers}
                            alt="Key Competitors"
                            className="segment-image"
                        />
                        <h3>Franchise Chains</h3>
                        <p>
                            Streamline procurement for Franchise Chains with
                            consistent, high-quality products and services.
                        </p>
                    </div>
                    <div className="market-segment">
                        <img
                            src={Competitor}
                            alt="Competitive Advantage"
                            className="segment-image"
                        />
                        <h3>Food Service Distributors</h3>
                        <p>
                            Access reliable supplies for Food Service
                            Distributors, ensuring seamless distribution
                            operations.
                        </p>
                    </div>
                    <div className="market-segment">
                        <img
                            src={Trend}
                            alt="Trends & Opportunities"
                            className="segment-image"
                        />
                        <h3>Events and Banquet Halls</h3>
                        <p>
                            Source premium products for Event Venues and Banquet
                            Halls, enhancing guest experiences.
                        </p>
                    </div>
                </div>
            </section>

            <h1 className="body-section-1-text">PRICING</h1>
            <section className="pricing-section">
                <div
                    className="pricing-card"
                    style={{ backgroundColor: '#f8d200' }}
                >
                    <h4>Basic</h4>
                    <h2>999 CAD</h2>
                    <ul>
                        <li>Essential kitchen supplies</li>
                        <li>Basic tableware and serveware</li>
                        <li>Monthly delivery</li>
                        <li>Customer support during business hours</li>
                    </ul>
                </div>
                <div
                    className="pricing-card"
                    style={{ backgroundColor: '#5f239d' }}
                >
                    <h4>Advanced</h4>
                    <h2 style={{ color: 'yellow' }}>1999 CAD</h2>
                    <ul style={{ color: 'white' }}>
                        <li>Premium kitchen equipment</li>
                        <li>Full range of tableware and serveware</li>
                        <li>Bi-weekly delivery</li>
                        <li>Priority customer support</li>
                    </ul>
                </div>
                <div
                    className="pricing-card"
                    style={{ backgroundColor: '#f8d200' }}
                >
                    <h4>Premium</h4>
                    <h2>2999 CAD</h2>
                    <ul>
                        <li>Comprehensive HoReCa solution</li>
                        <li>Commercial-grade kitchen equipment</li>
                        <li>Furniture & decor for dining areas</li>
                        <li>Weekly delivery</li>
                    </ul>
                </div>
            </section>

            <section className="management-team">
                <h1 className="body-section-1-text">LEADERSHIP</h1>

                <div className="leaders">
                    <div className="leader">
                        <img src={CEO} alt="Leader 1" />
                        <p style={{ fontWeight: 600 }}>Syed Ibn-e-Ali</p>
                        <p>CEO</p>
                    </div>
                    <div className="leader">
                        <img src={CTO} alt="Leader 2" />
                        <p style={{ fontWeight: 600 }}>S. Majid Ul Hassan</p>
                        <p>CTO</p>
                    </div>
                    <div className="leader">
                        <img src={EngineeringHead} alt="Member 1" />
                        <p style={{ fontWeight: 600 }}>Bilal Arif Siddiqui</p>
                        <p>Engineering Head</p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default HomeBody
